import { Fragment, useCallback } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { BookmarkAltIcon, CalendarIcon, ChartBarIcon, CursorClickIcon, MenuIcon, PhoneIcon, PlayIcon, RefreshIcon, ShieldCheckIcon, SupportIcon, ViewGridIcon, XIcon } from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import Link from 'next/link';
import { signOut, useSession } from 'next-auth/react';

const solutions = [
  {
    name: 'Analytics',
    description: 'Get a better understanding of where your traffic is coming from.',
    href: '#',
    icon: ChartBarIcon,
  },
  {
    name: 'Engagement',
    description: 'Speak directly to your customers in a more meaningful way.',
    href: '#',
    icon: CursorClickIcon,
  },
  { name: 'Security', description: "Your customers' data will be safe and secure.", href: '#', icon: ShieldCheckIcon },
  {
    name: 'Integrations',
    description: "Connect with third-party tools that you're already using.",
    href: '#',
    icon: ViewGridIcon,
  },
  {
    name: 'Automations',
    description: 'Build strategic funnels that will drive your customers to convert',
    href: '#',
    icon: RefreshIcon,
  },
];
const callsToAction = [
  { name: 'Watch Demo', href: '#', icon: PlayIcon },
  { name: 'Contact Sales', href: '#', icon: PhoneIcon },
];
const resources = [
  {
    name: 'Help Center',
    description: 'Get all of your questions answered in our forums or contact support.',
    href: '#',
    icon: SupportIcon,
  },
  {
    name: 'Guides',
    description: 'Learn how to maximize our platform to get the most out of it.',
    href: '#',
    icon: BookmarkAltIcon,
  },
  {
    name: 'Events',
    description: 'See what meet-ups and other events we might be planning near you.',
    href: '#',
    icon: CalendarIcon,
  },
  { name: 'Security', description: 'Understand how we take your privacy seriously.', href: '#', icon: ShieldCheckIcon },
];
const recentPosts = [
  { id: 1, name: 'Boost your conversion rate', href: '#' },
  { id: 2, name: 'How to use search engine optimization to drive traffic to your site', href: '#' },
  { id: 3, name: 'Improve your customer experience', href: '#' },
];

export default function Header() {
  const { data: session } = useSession();

  const closeSession = useCallback(() => {
    signOut({ callbackUrl: '/login' });
  }, []);

  return (
    <Popover className='relative bg-white dark:bg-slate-900'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6'>
        <div className='flex justify-between items-center border-b-2 border-gray-100 dark:border-slate-800 py-6 md:justify-start md:space-x-10'>
          <div className='flex justify-start lg:w-0 lg:flex-1'>
            <Link href='/'>
              <a>
                <span className='sr-only'>TrackNFTs</span>
                <img className='h-12 w-auto sm:h-16' src='/assets/tracknfts-logo.svg' alt='TrackNFTs Logo' />
              </a>
            </Link>
          </div>
          <div className='-mr-2 -my-2 md:hidden'>
            <Popover.Button className='bg-white dark:bg-slate-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500'>
              <span className='sr-only'>Open menu</span>
              <MenuIcon className='h-6 w-6' aria-hidden='true' />
            </Popover.Button>
          </div>
          {/* <Popover.Group as='nav' className='hidden md:flex space-x-10'>
            <Popover className='relative'>
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900 dark:text-white' : 'text-gray-500',
                      'group bg-white dark:bg-slate-900 rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                    )}>
                    <span>Solutions</span>
                    <ChevronDownIcon className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500')} aria-hidden='true' />
                  </Popover.Button>

                  <Transition as={Fragment} enter='transition ease-out duration-200' enterFrom='opacity-0 translate-y-1' enterTo='opacity-100 translate-y-0' leave='transition ease-in duration-150' leaveFrom='opacity-100 translate-y-0' leaveTo='opacity-0 translate-y-1'>
                    <Popover.Panel className='absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2'>
                      <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden'>
                        <div className='relative grid gap-6 bg-white dark:bg-slate-900 px-5 py-6 sm:gap-8 sm:p-8'>
                          {solutions.map((item) => (
                            <a key={item.name} href={item.href} className='-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50'>
                              <item.icon className='flex-shrink-0 h-6 w-6 text-red-600' aria-hidden='true' />
                              <div className='ml-4'>
                                <p className='text-base font-medium text-gray-900 dark:text-white'>{item.name}</p>
                                <p className='mt-1 text-sm text-gray-500'>{item.description}</p>
                              </div>
                            </a>
                          ))}
                        </div>
                        <div className='px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8'>
                          {callsToAction.map((item) => (
                            <div key={item.name} className='flow-root'>
                              <a href={item.href} className='-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100'>
                                <item.icon className='flex-shrink-0 h-6 w-6 text-gray-400' aria-hidden='true' />
                                <span className='ml-3'>{item.name}</span>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            <a href='#' className='text-base font-medium text-gray-500 hover:text-gray-900'>
              Pricing
            </a>
            <a href='#' className='text-base font-medium text-gray-500 hover:text-gray-900'>
              Docs
            </a>

            <Popover className='relative'>
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(open ? 'text-gray-900' : 'text-gray-500', 'group bg-white dark:bg-slate-900 rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500')}>
                    <span>More</span>
                    <ChevronDownIcon className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500')} aria-hidden='true' />
                  </Popover.Button>

                  <Transition as={Fragment} enter='transition ease-out duration-200' enterFrom='opacity-0 translate-y-1' enterTo='opacity-100 translate-y-0' leave='transition ease-in duration-150' leaveFrom='opacity-100 translate-y-0' leaveTo='opacity-0 translate-y-1'>
                    <Popover.Panel className='absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0'>
                      <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden'>
                        <div className='relative grid gap-6 bg-white dark:bg-slate-900 px-5 py-6 sm:gap-8 sm:p-8'>
                          {resources.map((item) => (
                            <a key={item.name} href={item.href} className='-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50'>
                              <item.icon className='flex-shrink-0 h-6 w-6 text-red-600' aria-hidden='true' />
                              <div className='ml-4'>
                                <p className='text-base font-medium text-gray-900'>{item.name}</p>
                                <p className='mt-1 text-sm text-gray-500'>{item.description}</p>
                              </div>
                            </a>
                          ))}
                        </div>
                        <div className='px-5 py-5 bg-gray-50 sm:px-8 sm:py-8'>
                          <div>
                            <h3 className='text-sm tracking-wide font-medium text-gray-500 uppercase'>Recent Posts</h3>
                            <ul role='list' className='mt-4 space-y-4'>
                              {recentPosts.map((post) => (
                                <li key={post.id} className='text-base truncate'>
                                  <a href={post.href} className='font-medium text-gray-900 hover:text-gray-700'>
                                    {post.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className='mt-5 text-sm'>
                            <a href='#' className='font-medium text-red-600 hover:text-red-500'>
                              {' '}
                              View all posts <span aria-hidden='true'>&rarr;</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group> */}
          <div className='hidden md:flex items-center justify-end md:flex-1 lg:w-0'>
            {session ? (
              <>
                <Link href='/my-account'>
                  <a className='whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700'>My Account</a>
                </Link>
                <button onClick={closeSession} className='ml-8 whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900 hover:dark:text-gray-100'>
                  Sign Out
                </button>
              </>
            ) : (
              <>
                <Link href='/login'>
                  <a className='whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900 hover:dark:text-gray-100'>Sign in</a>
                </Link>

                <Link href='/register'>
                  <a className='ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700'>Sign up</a>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>

      <Transition as={Fragment} enter='duration-200 ease-out' enterFrom='opacity-0 scale-95' enterTo='opacity-100 scale-100' leave='duration-100 ease-in' leaveFrom='opacity-100 scale-100' leaveTo='opacity-0 scale-95'>
        <Popover.Panel focus className='absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden'>
          <div className='rounded-lg shadow-lg ring-1 ring-black dark:ring-white ring-opacity-5 bg-white dark:bg-slate-800 divide-y-2 divide-gray-50 dark:divide-slate-700'>
            <div className='pt-5 pb-6 px-5'>
              <div className='flex items-center justify-between'>
                <div>
                  <img className='h-8 w-auto' src='assets/tracknfts-logo.svg' alt='TrackNFTs Logo' />
                </div>
                <div className='-mr-2'>
                  <Popover.Button className='bg-white dark:bg-slate-800 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-slate-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500'>
                    <span className='sr-only'>Close menu</span>
                    <XIcon className='h-6 w-6' aria-hidden='true' />
                  </Popover.Button>
                </div>
              </div>
              {/* <div className='mt-6'>
                <nav className='grid gap-y-8'>
                  {solutions.map((item) => (
                    <a key={item.name} href={item.href} className='-m-3 p-3 flex items-center rounded-md hover:bg-gray-50'>
                      <item.icon className='flex-shrink-0 h-6 w-6 text-red-600' aria-hidden='true' />
                      <span className='ml-3 text-base font-medium text-gray-900'>{item.name}</span>
                    </a>
                  ))}
                </nav>
              </div> */}
            </div>
            <div className='py-6 px-5 space-y-6'>
              {/* <div className='grid grid-cols-2 gap-y-4 gap-x-8'>
                <a href='#' className='text-base font-medium text-gray-900 hover:text-gray-700'>
                  Pricing
                </a>

                <a href='#' className='text-base font-medium text-gray-900 hover:text-gray-700'>
                  Docs
                </a>
                {resources.map((item) => (
                  <a key={item.name} href={item.href} className='text-base font-medium text-gray-900 hover:text-gray-700'>
                    {item.name}
                  </a>
                ))}
              </div> */}
              <div>
                <Link href='/register'>
                  <a className='w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700'>Sign up</a>
                </Link>
                <p className='mt-6 text-center text-base font-medium text-gray-500'>
                  Existing account?{' '}
                  <Link href='/login'>
                    <a className='text-red-600 hover:text-red-500'>Sign in</a>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
